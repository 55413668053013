import { React, useState, useEffect} from 'react'
import Layout from "utils/layout/Layout.jsx";
import { useTranslation } from "react-i18next";
import { useAppState } from "states/appState";
import { Navigate } from 'react-router-dom';
import { routes } from 'utils/auth0/constants'
import Loader from "components/Loader/Index";
import { setAccessTokenDspInfo } from 'utils/auth0/common';

import { getCurrentUserAsync, anonymizeUser } from 'datas/user';

import DeleteIMG from "../../assets/images/delete.svg"

const DeleteProfile = () => {
  const [loader, setLoader] = useState(true);
  const { i18n, t } = useTranslation();
  const [appContext, setAppContext] = useAppState();

  useEffect(() => {
    if(appContext.access_token.rawToken) {
      getCurrentUserAsync({ token: appContext.access_token.rawToken, language: i18n.language })
        .then(user => {
          setAccessTokenDspInfo(setAppContext, user);
          setLoader(false);
        })
        .catch(() => console.error("Failed to get user info"));
    }

    document.title = t('metaTags.pagetitle.deleteProfile');
  }, [appContext.access_token.rawToken]);

  if(!appContext.access_token.validToken){
    return <Navigate replace to={routes.Error} />
  }

  const handleSubmit = (event) => {
    event.preventDefault();

	  setLoader(true);

    anonymizeUser({ token: appContext.access_token.rawToken })
      .then(() => {
        setLoader(false);
        window.location.replace(appContext.access_token.redirectUri);
      })
      .catch(() => console.error("Failed to anonymize user"));
  }

  function redirectToOrigin(e) {
    e.preventDefault();
    window.location.replace("https://" + appContext.access_token.origin);
  }

  return (
    <Layout title={t('profile.delete.title')} containerClass="Profile-content--flCenter">
	  {loader && <Loader />}
      <div className="Profile-image">
        <img src={DeleteIMG} alt="delete icon"/>
      </div>
      <h2 className="Profile-title Profile-title--sub">{t('profile.delete.subTitle')}</h2>
      <p className="Profile-text">
        {t('profile.delete.text')}
      </p>
      <div className={appContext.styles.theme === 'dark' ? 'Profile-partner Profile-partner--dark' : 'Profile-partner'}>
        <div className="Profile-partnerContainer">
          <img width="40" height="60" src={appContext.styles.theme === 'dark' ? 'https://profile.ligue1.fr/static-assets/Images/profile-delete/l1l2.png' : 'https://profile.ligue1.fr/static-assets/Images/profile-delete/l1l2-black.png'} alt="Ligue1 Ligue2" className="Profile-partnerLogo"/>
        </div>
        <div className="Profile-partnerContainer">
          <img width="40" height="40" src="https://profile.ligue1.fr/static-assets/Images/profile-delete/mpg.png" alt="MPG" className="Profile-partnerLogo"/>
        </div>
        <div className="Profile-partnerContainer">
          <img width="40" height="40" src={appContext.styles.theme === 'dark' ? 'https://profile.ligue1.fr/static-assets/Images/profile-delete/mpp.png' : 'https://profile.ligue1.fr/static-assets/Images/profile-delete/mpp_dark.png'} alt="MPP" className="Profile-partnerLogo"/>
        </div>
      </div>
      <button type="submit" className="Button Button--red" onClick={handleSubmit}>{t('profile.delete.btnDelete')}</button>
      { appContext.access_token.origin && <a href="#!" onClick={redirectToOrigin} className="Button Button--outlined">{t('profile.delete.btnCancel')}</a> }
    </Layout>
  )
}

export default DeleteProfile;
