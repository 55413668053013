import { React, useCallback, useState, useEffect } from 'react'
import Layout from "utils/layout/Layout.jsx";
import FormGroup from "components/FormElement/FormGroup";
import Input from "components/FormElement/Input";
import Label from "components/FormElement/Label";
import { useTranslation } from "react-i18next";
import { useAppState } from "states/appState";
import { Navigate } from 'react-router-dom';
import { routes } from 'utils/auth0/constants'
import { optinTypes, getCurrentUserOptinsAysnc, updateCurrentUserOptinsAysnc, optinStatuses } from "datas/optins";
import Loader from "../../components/Loader/Index";
import { toast, Toaster } from "react-hot-toast";
import Toast from "../../components/Toast/Index";

const EditOptin = () => {
  const [userOptins , setUserOptins] = useState({ userId: '', optins: [], optinsByType: {} });
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  const [appContext] = useAppState();

  const customSetUserOptins = useCallback((newInfos) => {
    setUserOptins((oldInfos) => {
      const optinsByType = newInfos.optins.reduce((acc, optin) => {
        acc[optin.optinType] = optin;
        return acc;
      }, {});

      return { ...oldInfos, ...newInfos, optinsByType };
    });
  }, []);

  useEffect(() => {
    if(appContext.access_token.rawToken) {
      getCurrentUserOptinsAysnc(appContext.access_token.rawToken)
        .then(userOptinsPayload => {
          customSetUserOptins(userOptinsPayload);
          setLoader(false);
        })
        .catch(() => console.error("Failed to get user optins"));
    }

    document.title = t('metaTags.pagetitle.profileOptin');
  }, [appContext.access_token.rawToken]);

  if(!appContext.access_token.validToken){
    return <Navigate replace to={routes.Error} />
  }

  const handleChange = (event) => {
    const isChecked = event.target.checked ? optinStatuses.ENABLED : optinStatuses.DISABLED;

    event.target.name.split("|").forEach((optinType) => {
      const optin = userOptins.optinsByType[optinType];
      if (optin) {
        optin.status = isChecked;
      } else {
        userOptins.optins.push({
          optinType,
          status: isChecked
        });
      }
    });

    customSetUserOptins(userOptins);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);

    const { optinsByType, ...cleanUserOptins } = userOptins
    updateCurrentUserOptinsAysnc(cleanUserOptins, appContext.access_token.rawToken)
      .then(_ => {
        setLoader(false);
        toast((toast) => (
          <Toast text={t('profile.optin.toast')} t={toast} />
        ),{
          duration: 5000,
        });
      })
      .catch(() => console.error("Failed to update user optins"));
  }

  const optinParnerStatus = userOptins.optinsByType[optinTypes.NEWSLETTER_PARTNERS]?.status;
  const optinTicketingStatus = userOptins.optinsByType[optinTypes.TICKETING]?.status;
  const optinLigue1Status = userOptins.optinsByType[optinTypes.NEWSLETTER_L1]?.status;
  const optinLigue2Status = userOptins.optinsByType[optinTypes.NEWSLETTER_L2]?.status;

  const mpgOptin = userOptins.optinsByType[optinTypes.MPG];
  const mpgOptinData = {
    isEnabled: mpgOptin,
    status: mpgOptin?.status,
  };

  const mppOptin = userOptins.optinsByType[optinTypes.MPP];
  const mppOptinData = {
    isEnabled: mppOptin,
    status: mppOptin?.status,
  };

  const coachL1Optin = userOptins.optinsByType[optinTypes.COACH_L1];
  const coachL1OptinData = {
    isEnabled: coachL1Optin,
    status: coachL1Optin?.status,
  };

  const playL1Optin = userOptins.optinsByType[optinTypes.PLAY_L1];
  const playL1OptinData = {
    isEnabled: playL1Optin,
    status: playL1Optin?.status
  };

  return (
    <Layout title={t('profile.optin.title')} iconHeaderRight="true">
      <Toaster />
      <form onSubmit={handleSubmit} className="FormElement">
        {loader && <Loader />}
        <p className="FormElement-description">{t('profile.optin.description')}</p>
        <div className="FormElement-container">
          <FormGroup className={`FormElement-group--checkboxToggle ${optinLigue1Status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinLigue1" type="checkbox" name={optinTypes.NEWSLETTER_L1} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!optinLigue1Status}/>
            <Label text={t('profile.optin.Ligue1')} id="optinLigue1" description={t('profile.optin.Ligue1Description')} />
          </FormGroup>
          <FormGroup className={`FormElement-group--checkboxToggle ${optinLigue2Status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinLigue2" type="checkbox" name={optinTypes.NEWSLETTER_L2} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!optinLigue2Status}/>
            <Label text={t('profile.optin.Ligue2')} id="optinLigue2" description={t('profile.optin.Ligue2Description')} />
          </FormGroup>
          <FormGroup className={`FormElement-group--checkboxToggle ${optinParnerStatus ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinParner" type="checkbox" name={optinTypes.NEWSLETTER_PARTNERS} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!optinParnerStatus}/>
            <Label rawHtml={t('profile.optin.Partners')} id="optinParner" description={t('profile.optin.PartnersDescription')} />
          </FormGroup>
          <FormGroup className={`FormElement-group--checkboxToggle ${optinTicketingStatus ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinTicketing" type="checkbox" name={optinTypes.TICKETING} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!optinTicketingStatus}/>
            <Label text={t('profile.optin.Ticketing')} id="optinTicketing" description={t('profile.optin.TicketingDescription')} />
          </FormGroup>
          {mpgOptinData.isEnabled && <FormGroup className={`FormElement-group--checkboxToggle ${mpgOptinData.status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinMPG" type="checkbox" name={optinTypes.MPG} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!mpgOptinData.status}/>
            <Label text={t('profile.optin.MPG')} id="optinMPG" description={t('profile.optin.MPGDescription')} />
          </FormGroup>}
          {mppOptinData.isEnabled && <FormGroup className={`FormElement-group--checkboxToggle ${mppOptinData.status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinMPP" type="checkbox" name={optinTypes.MPP} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!mppOptinData.status}/>
            <Label text={t('profile.optin.MPP')} id="optinMPP" description={t('profile.optin.MPPDescription')} />
          </FormGroup>}
          {coachL1OptinData.enable && <FormGroup className={`FormElement-group--checkboxToggle ${coachL1OptinData.status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinCoachL1" type="checkbox" name={optinTypes.COACH_L1} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!coachL1OptinData.status}/>
            <Label text={t('profile.optin.CoachL1')} id="optinCoachL1" description={t('profile.optin.CoachL1Description')} />
          </FormGroup>}
          {playL1OptinData.enable && <FormGroup className={`FormElement-group--checkboxToggle ${playL1OptinData.status ? 'FormElement-group--checked' : ''}`}>
            <Input id="optinPlayL1" type="checkbox" name={optinTypes.PLAY_L1} className="FormElement-input--checkboxToggle"
                   onChange={handleChange} checked={!!playL1OptinData.status}/>
            <Label text={t('profile.optin.PlayL1')} id="optinPlayL1" description={t('profile.optin.PlayL1Description')} />
          </FormGroup>}

        </div>

        <button type="submit" className="Button">{t('profile.sendBtn')}</button>
      </form>
    </Layout>
  )
}

export default EditOptin;
